
@font-face {
  font-family: 'telefonica_headline_lightRg';
  src: url('fonts/copia_de_telefonicaheadline-light-webfont.woff2') format('woff2'),
  url('fonts/copia_de_telefonicaheadline-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'telefonica_textbold';
  src: url('fonts/copia_de_telefonicatext-bold-webfont.woff2') format('woff2'),
  url('fonts/copia_de_telefonicatext-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'telefonica_textitalic';
  src: url('fonts/copia_de_telefonicatext-italic-webfont.woff2') format('woff2'),
  url('fonts/copia_de_telefonicatext-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'telefonica_textregular';
  src: url('fonts/copia_de_telefonicatext-regular-webfont.woff2') format('woff2'),
  url('fonts/copia_de_telefonicatext-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fi{  font-family: 'telefonica_textitalic';     }
.fb{  font-family: 'telefonica_textbold';       }
.fl{ font-family: 'telefonica_headline_lightRg';}

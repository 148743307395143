@import "fuentes";


*{
  border:0;
  padding:0;
  margin:0;
  box-sizing: border-box;
}


body,html,#root{
    width:100%;
    height:100%;
    line-height: 1.4em;
    font-size: 16px;
    overflow: hidden;
    font-family: 'telefonica_textregular';
  color: #50535a;
}

.main-container{
  width:100%;
  height:100%;
  overflow: hidden auto;

  @media all and (min-width:768px){
    margin-top: 72px;
  }
}

.blur{
    filter: blur(5px);

}

.ro{
  border:1px solid red;
}

.bgb{
  background:white;
}

button,input,select,textarea{
  &:focus{
    outline: none;
  }
}

.ReactCollapse--collapse {
  transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
};

.wc{width:100%;}

.imgr{
  width:100%;
  height:auto;
  display:block;
}


@keyframes girar {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes carga {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes gost {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}

.cv{
  color: #5bc500;
}